import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
const Login = (props) => {
  const url = "https://ks10sportsacademy.herokuapp.com"
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (event) => {
  
    event.preventDefault();
    fetch(`${url}/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coachEmail: email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("resss", res);
        console.log(email);
        if (res.message === "Authorization failed") {
          toast.info("Unauthorized User, please enter a valid email address");
        } else if (res.success) {
          localStorage.setItem("coachId", res.user._id);
          localStorage.setItem("token", res.token);
          console.log("token", res.token);
          window.location.href = "./#/addplayers";
          toast.success("Login successful");
        }
      });
  };
  return (
    <div className="container">
      <header className="jumbotron">
        <h2>Enter your email below to login</h2>
      </header>
      <div className="loginForm">
        <div className="container">
          <form onSubmit={handleSubmit} className="registrationForm">
            <h1>Login</h1>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                value={email}
                onChange={handleChange}
                name="email"
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            autoClose={4000}
            newestOnTop={true}
            closeOnClick={false}
            draggable={false}
            rtl={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
