import React, { useState, useEffect } from "react";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Home from "./components/Home";
import Register from "./components/Register";
import Welcome from "./components/Welcome";
import Login from "./components/login";
import AddPlayers from "./components/addplayerForm";
import jwtDecode from "jwt-decode";
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const stripePromise = loadStripe(process.env.REACT_APP_PUB_KEY);
const url = "https://ks10sportsacademy.herokuapp.com";
const App = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${url}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "the-tournament" }] }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setAmount(data.amount);
      });
  }, []);

  useEffect(() => {
    try {
      const jwt = localStorage.getItem("token");
      const user = jwtDecode(jwt);
      setUser(user);
      console.log(user);
    } catch (ex) {}
  }, []);
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {/* {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )} */}
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <HashRouter history={history}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/register">
                <Register amount={amount} />
              </Route>
              <Route exact path="/login" component={Login} />
              <Route
                path="/addplayers"
                render={(props) => {
                  if (!user) return <Redirect to="/" />;
                  return <AddPlayers {...props} />;
                }}
              />
              <Route path="/confirm/:confirmationCode" component={Welcome} />
            </Switch>
          </HashRouter>
        </Elements>
      )}
    </>
  );
};

export default App;
