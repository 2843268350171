import React from "react";
import { ToastContainer } from "react-toastify";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const coachId = localStorage.getItem("coachId");
const AddPlayer = (props) => {
  const url = "https://ks10sportsacademy.herokuapp.com";
  const form = useFormik({
    initialValues: {
      players: [
        {
          name: "",
          playerNumber: "",
          age: "",
          position: "",
          grade: "",
        },
      ],
    },
    onSubmit: (values) => {
      for (let i = 0; i < values.players.length; i++) {
        console.log(form.values.players);
        fetch(`${url}/player/${coachId}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: values.players[i].name,
            playerNumber: values.players[i].playerNumber,
            age: values.players[i].age,
            position: values.players[i].position,
            grade: values.players[i].grade,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);

            if (res) {
              toast.success("player successfully added");
              form.resetForm({ values: "" });
              window.location.reload();
              // it should redirect them to their profile page at this point
            } else if (res.error) {
              console.log("error", res.err);
              toast.error(res.err);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  });

  const playerComponent = (i) => (
    <div className="row gx-3 gy-3" key={i}>
      <div className="col-12 col-lg-1">
        <Form.FloatingLabel controlId="playerNo" label="No.">
          <Form.Control
            type="text"
            placeholder="No."
            name={`players[${i}.playerNumber]`}
            onChange={form.handleChange}
            // value={form.values.players[i].playerNumber}
          />
        </Form.FloatingLabel>
      </div>
      <div className="col-12 col-lg-3">
        <Form.FloatingLabel controlId="playerName" label="Player Name">
          <Form.Control
            type="text"
            placeholder="Player Name"
            name={`players[${i}.name]`}
            onChange={form.handleChange}
            // value={form.values.players[i].name}
          />
        </Form.FloatingLabel>
      </div>
      <div className="col-12 col-lg-2">
        <Form.FloatingLabel controlId="playerAge" label="Player Age">
          <Form.Control
            type="text"
            placeholder="Player Age"
            name={`players[${i}.age]`}
            onChange={form.handleChange}
            // value={form.values.players[i].age}
          />
        </Form.FloatingLabel>
      </div>
      <div className="col-12 col-lg-3">
        <Form.FloatingLabel controlId="coachEmail" label="Position">
          <Form.Select
            className="form-select"
            placeholder="Position"
            name={`players[${i}.position]`}
            onChange={form.handleChange}
            // value={form.values.players[i].position}
          >
            <option value="">Select Position</option>
            {positions.map((position, i) => (
              <option key={`position${i}`} value={position.value}>
                {position.label}
              </option>
            ))}
          </Form.Select>
        </Form.FloatingLabel>
      </div>
      <div className="col-12 col-lg-2">
        <Form.FloatingLabel controlId="coachGrade" label="Grade">
          <Form.Select
            className="form-select"
            placeholder="Grade"
            name={`players[${i}.grade]`}
            onChange={form.handleChange}
            // value={form.values.players[i].grade}
          >
            <option value="">Select Grade</option>
            {grades.map((grade, i) => (
              <option key={`grade${i}`} value={grade.value}>
                {grade.label}
              </option>
            ))}
          </Form.Select>
        </Form.FloatingLabel>
      </div>
      <div className="col-12 col-lg-1">
        <div className="form-btn-wrappers">
          <button
            className="btn btn-secondary"
            onClick={addPlayer}
            type="button"
          >
            +
          </button>
          {form.values.players.length > 1 ? (
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => removePlayer(i)}
            >
              -
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );

  const addPlayer = () => {
    const player = {
      name: "",
      playerNumber: "",
      age: "",
      position: "",
      grade: "",
    };

    form.setFieldValue("players", [...form.values.players, player]);
  };

  const removePlayer = (i) => {
    form.values.players.splice(i, 1);
    form.setFieldValue("players", [...form.values.players]);
  };

  return (
    <div className="form">
      <div className="container">
        <form onSubmit={form.handleSubmit} className="form-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="cls-btn"
                  role="button"
                  onClick={props.onCloseClick}
                >
                  <i className="bi bi-x-circle"></i>
                </div>
                <fieldset>
                  <legend>Team players</legend>
                  <div className="row gx-3 gy-3">
                    {form.values.players.map((player, i) => playerComponent(i))}
                  </div>
                </fieldset>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary">
                  Create players
                </button>
              </div>
            </div>
          </div>

          {/* {data.errorMessage && (
            <span className="form-error">{data.errorMessage}</span>
          )} */}
        </form>

        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={2000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        />
      </div>
    </div>
  );
};

const positions = [
  { label: "Quarterback", value: "Quarterback" },
  { label: "Lineman", value: "Lineman" },
  { label: "Safety", value: "Safety" },
  { label: "Corner back", value: "Corner back" },
  { label: "Full back", value: "Full back" },
  { label: "Tightend", value: "Tightend" },
  { label: "Center", value: "Center" },
  { label: "Wide Reciever", value: "Wide Reciever" },
  { label: "Defense", value: "Defense" },
  { label: "Defense and Tackle", value: "Defense and Tackle" },
  { label: "Kicker", value: "Kicker" },
  { label: "Linebacker", value: "Linebacker" },
  { label: "Guard", value: "Guard" },
];

const grades = [
  { label: "1st Grade", value: "1st Grade" },
  { label: "2nd Grade", value: "2nd Grade" },
  { label: "3rd Grade", value: "3rd Grade" },
  { label: "4th Grade", value: "4th Grade" },
  { label: "5th Grade", value: "5th Grade" },
  { label: "6th Grade", value: "6th Grade" },
  { label: "7th Grade", value: "7th Grade" },
  { label: "8th Grade", value: "8th Grade" },
  { label: "9th Grade", value: "9th Grade" },
  { label: "10th Grade", value: "10th Grade" },
  { label: "11th Grade", value: "11th Grade" },
  { label: "12th Grade", value: "12th Grade" },
];

export default AddPlayer;
