import React from "react";
// import VerifyUser from "./verifyuser";
import { Link } from "react-router-dom";
import axios from "axios";
const Welcome = (props) => {
  const url = "https://ks10sportsacademy.herokuapp.com";
  let verifyUser = async (code) => {
    await axios
      .get(`${process.env.BACKEND_URL}/confirm/` + code)
      .then((response) => {
        console.log("token", response.data);
        return response.data;
      });
  };

  if (props.match.path === `${url}/#/confirm/:confirmationCode`) {
    verifyUser(props.match.params.confirmationCode);
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>Congratulations, Your Account has been confirmed!</strong>
        </h3>
      </header>
      <Link to={"/login"} className="nav-link">
        Please <button>Login</button> to add your team members
      </Link>
    </div>
  );
};

export default Welcome;
